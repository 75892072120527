import React, { useEffect, useState } from "react";
import { users, clients, rooms } from "../fakerData/index";
import { Box, Button, Layer, Tab, Tabs, Text } from "grommet";
import MyRooms from "../Components/GoogleRooms/MyRooms";
import { API, Auth } from "aws-amplify";
import {
  getGoogleRoomDetails,
  getGoogleRoomsByCreatedAtDate,
  getGoogleRoomsByOrganizer,
  getInitialGoogleRoomData,
  getUser,
} from "../graphql/queries";
import dayjs from "dayjs";
import { Add } from "grommet-icons";
import { createUser, updateUser } from "../graphql/mutations";
import { useGoogleLogin } from "@react-oauth/google";
import CreateRoomForm from "../Components/GoogleRooms/CreateRoomForm";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);
// 5 search contexts by mine, by all, by user, by client, by date

function GoogleRoomIntegration({ adminStatus }) {
  const [roomList, setRoomList] = useState([]);
  const [clientList, setClientList] = useState(clients);
  const [userList, setUserList] = useState(users);
  const [tabIndex, setTabIndex] = useState(0);
  const [currentUser, setCurrentUser] = useState({});
  const [isRoomList, setIsRoomList] = useState(true);
  const [showGoogleLogin, setShowGoogleLogin] = useState(false);
  const [showGoogleRoomForm, setShowGoogleRoomForm] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // effect to fetch initial data sets
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        fetchInitialData(user.attributes.email);
      })
      .catch((err) => {
        console.log(err);
      });

    Auth.currentUserInfo().then((user) => {
      setCurrentUser(user);
    });
    setIsFirstLoad(false);
  }, []);

  // effect when tabIndex changes to all rooms
  useEffect(() => {
    setRoomList([]);
    setIsRoomList(true);
    if (tabIndex === 0) {
      if (!isFirstLoad) {
        fetchInitialData(currentUser.attributes.email);
      }
    }
    if (tabIndex === 1) {
      const newRoomList = fetchTodayRooms([], null).then((res) => {
        console.log(res);
        setRoomList(res);
        if (res.length > 0) {
          setIsRoomList(true);
        } else {
          setIsRoomList(false);
        }
      });
    }
  }, [tabIndex]);
  // fetch initial data
  async function fetchInitialData(user) {
    const data = await API.graphql({
      query: getInitialGoogleRoomData,
    });

    const roo = await fetchRoomsByOrganizer(user, [], null).then((res) => {
      setRoomList(res);

      if (res.length > 0) {
        setIsRoomList(true);
      } else {
        setIsRoomList(false);
      }
    });
    setUserList(data.data.listUsers.items);
    setClientList(data.data.listClients.items);
  }
  // fetch rooms by organizer
  async function fetchRoomsByOrganizer(organizer, list, nextToken) {
    let temp = list;
    const data = await API.graphql({
      query: getGoogleRoomsByOrganizer,
      variables: nextToken
        ? { organizer: organizer, sortDirection: "DESC", nextToken: nextToken }
        : { organizer: organizer, sortDirection: "DESC" },
    });

    temp = [...temp, ...data.data.googleRoomByOrganizer.items];

    return data.data.googleRoomByOrganizer.nextToken
      ? await fetchRoomsByOrganizer(
          organizer,
          temp,
          data.data.googleRoomByOrganizer.nextToken
        )
      : temp;
  }
  // fetch today's rooms
  async function fetchTodayRooms(list, nextToken) {
    // get today's date
    const today = dayjs().tz("America/Chicago").format("YYYY-MM-DD");

    let temp = list;
    const data = await API.graphql({
      query: getGoogleRoomsByCreatedAtDate,
      variables: nextToken
        ? {
            createdAtDate: today,
            nextToken: nextToken,
          }
        : { createdAtDate: today },
    });

    temp = [...temp, ...data.data.googleRoomByDate.items];

    return data.data.googleRoomByDate.nextToken
      ? await fetchTodayRooms(temp, data.data.googleRoomByDate.nextToken)
      : temp;
  }
  // create room prompt
  async function createRoomPrompt() {
    // check if user exists and has google access token
    const check = await API.graphql({
      query: getUser,
      variables: {
        id: currentUser.id,
      },
    });
    console.log(check);
    if (check.data.getUser === null) {
      await API.graphql({
        query: createUser,
        variables: {
          input: {
            id: currentUser.id,
            email: currentUser.attributes.email,
          },
        },
      });

      setShowGoogleLogin(true);
    } else if (check.data.getUser.googleAccessToken === null) {
      setShowGoogleLogin(true);
    } else {
      setShowGoogleRoomForm(true);
    }
  }

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      setGoogleTokens(codeResponse.code);
    },
    flow: "auth-code",
    redirect_uri: "postmessage",
    scope:
      "https://www.googleapis.com/auth/meetings.space.readonly https://www.googleapis.com/auth/meetings.space.created https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive.meet.readonly",
  });

  async function setGoogleTokens(code) {
    await API.graphql({
      query: getGoogleRoomDetails,
      variables: {
        action: "getInitialTokens",
        payload: JSON.stringify({
          code: code,
        }),
      },
    }).then((res) => {
      console.log(res);
      let parsed = JSON.parse(res.data.getGoogleRoomDetails);
      console.log(parsed);
      if (parsed.statusCode === 200) {
        console.log("success");

        updateUserCreds(
          currentUser.id,
          parsed.body.access_token,
          parsed.body.refresh_token,
          parsed.body.expiry_date
        );
        setShowGoogleLogin(false);
        setShowGoogleRoomForm(true);
      }
    });
  }

  async function updateUserCreds(id, accessToken, refreshToken, expiry) {
    await API.graphql({
      query: updateUser,
      variables: {
        input: {
          id: id,
          googleAccessToken: accessToken,
          googleRefreshToken: refreshToken,
          googleTokenExpiry: expiry,
        },
      },
    });
  }
  return (
    <Box
      align="start"
      justify="start"
      fill="horizontal"
      width="full"
      gap="small"
      pad={{ top: "medium" }}
    >
      {showGoogleLogin && (
        <Layer>
          <Box
            align="center"
            justify="center"
            fill="horizontal"
            pad="medium"
            gap="small"
          >
            <Text>
              We need permission to access Google Meets, click below to
              authenticate.
            </Text>
            <Button
              label="Authenticate with Google"
              primary
              onClick={() => login()}
            />
          </Box>
        </Layer>
      )}
      {showGoogleRoomForm && (
        <Layer>
          <Box
            align="center"
            justify="center"
            fill="horizontal"
            pad="medium"
            gap="small"
          >
            <CreateRoomForm
              user={currentUser}
              setShowGoogleRoomForm={setShowGoogleRoomForm}
              fetchInitialData={fetchInitialData}
            />
          </Box>
        </Layer>
      )}
      <Button
        label="Add New Room"
        primary
        size="large"
        icon={<Add />}
        onClick={createRoomPrompt}
      />
      <Tabs
        activeIndex={tabIndex}
        onActive={(nextIndex) => setTabIndex(nextIndex)}
        alignControls="start"
        fill="horizontal"
      >
        <Tab title="My Rooms">
          <Box pad="medium" width="100%">
            <MyRooms
              roomList={roomList}
              tabIndex={0}
              isRoomList={isRoomList}
              setIsRoomList={setIsRoomList}
            />
          </Box>
        </Tab>
        {adminStatus && (
          <Tab title="All Rooms">
            <Box pad="medium" width="100%">
              <MyRooms
                roomList={roomList}
                tabIndex={1}
                setRoomList={setRoomList}
                isRoomList={isRoomList}
                setIsRoomList={setIsRoomList}
              />
            </Box>
          </Tab>
        )}
      </Tabs>
    </Box>
  );
}

export default GoogleRoomIntegration;
