import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { API } from "aws-amplify";
import { HpeByExamDate } from "../graphql/queries";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Text,
  Button,
  DateInput,
  Layer,
  Notification,
} from "grommet";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export default function HPECodes() {
  const [data, setData] = useState([]);
  const [selectedDay, setSelectedDay] = useState(
    dayjs().tz("America/Chicago").format("YYYY-MM-DD")
  );
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);
  const [showLayer, setShowLayer] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    try {
      API.graphql({
        query: HpeByExamDate,
        variables: { examDate: selectedDay },
      }).then((response) => {
        console.log("response", response);
        setData(response.data.hpeByExamDate.items);
      });
    } catch (error) {
      console.log("error on fetching data", error);
    }
  }, [selectedDay]);

  function handleBoxClick(item) {
    setSelectedExam(item);
    setShowLayer(true);
  }

  function handleCopyLink() {
    setShowNotification(true);

    navigator.clipboard.writeText(
      "https://scorpion.caveon.com/take?launch_token=" +
        selectedExam.candidateToken
    );
  }

  function handleCloseLayer() {
    setShowLayer(false);
    setSelectedExam(null);
  }

  return (
    <Box
      align="center"
      justify="center"
      margin={{ top: "medium", horizontal: "large" }}
      pad="medium"
    >
      {showNotification && (
        <Notification
          toast
          title="Link Copied!"
          status="normal"
          onClose={() => setShowNotification(false)}
        />
      )}
      {showLayer && (
        <Layer onEsc={handleCloseLayer} onClickOutside={handleCloseLayer}>
          <Box align="start" justify="center" pad="medium" gap="medium">
            <Box direction="row">
              <Text weight="bold">Name: </Text>
              <Text>{selectedExam.name}</Text>
            </Box>
            <Box direction="row">
              <Text weight="bold">Exam: </Text>
              <Text>
                {selectedExam.examName} - {selectedExam.examDuration}
              </Text>
            </Box>
            <Box direction="row">
              <Text weight="bold">Exam Date: </Text>
              <Text>
                {dayjs(selectedExam.examStart)
                  .tz("America/Chicago")
                  .format("MM/DD/YYYY hh:mm A")}
              </Text>
            </Box>
            <Box direction="row">
              <Text weight="bold">Booking ID: </Text>
              <Text>{selectedExam.id}</Text>
            </Box>
            <Box direction="row" gap="small">
              <Button
                primary
                color="black"
                label="Copy Tester Link"
                onClick={handleCopyLink}
              />
              <Button
                primary
                color="black"
                label="Open Proctor Link"
                onClick={() =>
                  window.open(
                    "https://scorpion.caveon.com/proctor/" +
                      selectedExam.proctorToken,
                    "_blank"
                  )
                }
              />
            </Box>
            <Button size="xsmall" label="Close" onClick={handleCloseLayer} />
          </Box>
        </Layer>
      )}
      <Box align="center" justify="center" fill>
        <Card border fill="horizontal" pad="medium" align="center">
          <CardHeader align="center" direction="column">
            <Text size="xlarge" weight="bolder">
              HPE Links
            </Text>
            <Text size="small" weight="light">
              Click on a tester to view their Links
            </Text>
          </CardHeader>
          <CardBody pad="small">
            {!showDatePicker && (
              <Button
                label="view previous days"
                size="xsmall"
                onClick={() => setShowDatePicker(true)}
              />
            )}
            {showDatePicker && (
              <Box align="center" justify="center" pad="medium">
                <DateInput
                  inline={true}
                  value={selectedDay}
                  onChange={({ value }) => setSelectedDay(value)}
                />
                <Button
                  label="view today"
                  size="xsmall"
                  onClick={() => {
                    setShowDatePicker(false);
                    setSelectedDay(
                      dayjs().tz("America/Chicago").format("YYYY-MM-DD")
                    );
                  }}
                />
              </Box>
            )}
          </CardBody>
        </Card>
      </Box>
      <Box align="center" justify="center" fill margin={{ top: "small" }}>
        {data.length > 0 && (
          <Box fill wrap gap="medium">
            {data.map((item) => (
              <Box
                key={item.id}
                align="start"
                justify="start"
                elevation="small"
                background={{ color: "white" }}
                pad="small"
                round="small"
                border
                onClick={() => {
                  handleBoxClick(item);
                }}
                focusIndicator={false}
                hoverIndicator={{ color: "light-3" }}
              >
                <Box direction="row">
                  <Text weight="bold">Name: </Text>
                  <Text>{item.name}</Text>
                </Box>
                <Box direction="row">
                  <Text weight="bold">Exam: </Text>
                  <Text>
                    {item.examName} - {item.examDuration}
                  </Text>
                </Box>
                <Box direction="row">
                  <Text weight="bold">Exam Date: </Text>
                  <Text>
                    {dayjs(item.examStart)
                      .tz("America/Chicago")
                      .format("MM/DD/YYYY hh:mm A")}
                  </Text>
                </Box>
                <Box direction="row">
                  <Text weight="bold">Booking ID: </Text>
                  <Text>{item.id}</Text>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
