import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Text,
  Notification,
} from "grommet";
import { useState } from "react";
export default function RoomList({ roomList, room }) {
  const [visible, setVisible] = useState(false);

  return (
    <Card background="white" margin="small" width="medium" elevation="medium">
      {visible && (
        <Notification
          toast
          status="normal"
          message="Copied to clipboard"
          onClose={() => setVisible(false)}
        />
      )}
      <CardHeader pad="small">
        <Box border="bottom" width="full">
          <Text size="large" weight="bold">
            {room.clientName}
          </Text>
          <Text size="large">{room.candidateName}</Text>
        </Box>
      </CardHeader>
      <CardBody pad="small">
        <Box>
          <Box direction="row" gap="small" justify="between">
            <Text size="small" weight="bold">
              Email:
            </Text>
            <Text size="small">{room.candidateEmail}</Text>
          </Box>
        </Box>
        <Box direction="row" gap="small" justify="between">
          <Text size="small" weight="bold">
            Organizer:
          </Text>

          <Text size="small">{room.organizer.split("@")[0]}</Text>
        </Box>
        <Box direction="row" gap="small" justify="between">
          <Text size="small" weight="bold">
            Date:
          </Text>
          <Text size="small">{room.createdAtDate}</Text>
        </Box>
        {room.mobileRoomCode && (
          <Box
            direction="column"
            gap="small"
            border="top"
            pad={{ bottom: "small" }}
          >
            <Text size="medium" weight="bold">
              Mobile
            </Text>
            <Box direction="row" gap="small" justify="between">
              <Text size="small" weight="bold">
                Mobile Room Code:
              </Text>
              <Text size="small">{room.mobileRoomCode}</Text>
            </Box>
            <Box direction="row" gap="small" justify="between">
              <Button
                label="Copy Mobile Link"
                onClick={() => {
                  setVisible(true);
                  navigator.clipboard.writeText(room.mobileRoomLink);
                }}
                primary
              />
            </Box>
          </Box>
        )}
        {room.webcamRoomCode && (
          <Box
            direction="column"
            gap="small"
            border="top"
            pad={{ bottom: "small" }}
          >
            <Text size="medium" weight="bold">
              Webcam
            </Text>
            <Box direction="row" gap="small" justify="between">
              <Text size="small" weight="bold">
                Webcam Room Code:
              </Text>
              <Text size="small">{room.webcamRoomCode}</Text>
            </Box>
            <Box direction="row" gap="small" justify="between">
              <Button
                label="Copy Webcam Link"
                onClick={() => {
                  setVisible(true);
                  navigator.clipboard.writeText(room.webcamRoomLink);
                }}
                primary
              />
            </Box>
          </Box>
        )}
        <Box direction="row" gap="small"></Box>
      </CardBody>
    </Card>
  );
}
