/* 
User form inputs 
candidateEmail
candidateName
clientId - from client db
clientName - from client db

Auto inputs
createdAtDate - today in Central Time (America/Chicago)
googleAccessToken - from user db
googleRefreshToken - from user db
googleTokenExpiry - from user db
organizer - from user db "id"

update roomCode - space.name in lambda
update roomName - space.link in lambda
*/
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import {
  getGoogleRoomDetails,
  getUser,
  listClients,
} from "../../graphql/queries";
import {
  Box,
  Button,
  Form,
  FormField,
  Select,
  TextInput,
  Spinner,
} from "grommet";
import { createGoogleRoom } from "../../graphql/mutations";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export default function CreateRoomForm({
  user,
  setShowGoogleRoomForm,
  fetchInitialData,
}) {
  const [clientOptions, setClientOptions] = useState([]);
  const [initialClients, setInitialClients] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getClients();
  }, []);

  async function getClients() {
    const clients = await API.graphql({
      query: listClients,
    });

    const sorted = clients.data.listClients.items.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    setClientOptions(sorted);
    setInitialClients(sorted);
  }

  async function createRoom(values) {
    setLoading(true);
    const today = dayjs().tz("America/Chicago").format("YYYY-MM-DD");
    const updatedUser = await API.graphql({
      query: getUser,
      variables: {
        id: user.id,
      },
    });
    console.log(values);

    //create room in db

    const room = await API.graphql({
      query: createGoogleRoom,
      variables: {
        input: {
          candidateEmail: values.candidateEmail,
          candidateName: values.candidateName,
          clientId: values.clientId.id,
          clientName: values.clientId.name,
          createdAtDate: today,
          googleAccessToken: updatedUser.data.getUser.googleAccessToken,
          googleRefreshToken: updatedUser.data.getUser.googleRefreshToken,
          googleTokenExpiry: updatedUser.data.getUser.googleTokenExpiry,
          organizer: user.attributes.email,
        },
      },
    });
    console.log(room);
    // create room in google, update to db is handled in lambda
    const updatedRoom = await API.graphql({
      query: getGoogleRoomDetails,
      variables: {
        action: "createRoom",
        payload: JSON.stringify({
          id: room.data.createGoogleRoom.id,
          refreshToken: room.data.createGoogleRoom.googleRefreshToken,
          clientId: room.data.createGoogleRoom.clientId,
          options: values.roomOptions,
        }),
      },
    });
    console.log(updatedRoom);
    setShowGoogleRoomForm(false);
    fetchInitialData(user.attributes.email);
  }
  return (
    <Box
      align="center"
      justify="center"
      direction="column"
      pad="small"
      gap="small"
    >
      {loading ? (
        <Spinner size="large" />
      ) : (
        <Form onSubmit={({ value }) => createRoom(value)}>
          <FormField name="candidateName" label="Candidate Name" required>
            <TextInput name="candidateName" />
          </FormField>
          <FormField name="candidateEmail" label="Candidate Email" required>
            <TextInput name="candidateEmail" type="email" />
          </FormField>
          <FormField name="clientId" label="Client" required>
            <Select
              name="clientId"
              options={clientOptions}
              labelKey="name"
              valueKey="id"
              onSearch={(text) => {
                // search clients
                if (!text) {
                  setClientOptions(initialClients);
                  return;
                }
                const search = initialClients.filter((client) =>
                  client.name.toLowerCase().includes(text.toLowerCase())
                );
                setClientOptions(search);
              }}
            />
          </FormField>
          <FormField name="roomOptions" label="Select Room Type" required>
            <Select
              options={["Mobile", "Webcam", "Mobile & Webcam"]}
              name="roomOptions"
            />
          </FormField>
          <Button type="submit" primary label="Create Room" />
        </Form>
      )}
    </Box>
  );
}
