import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProduct, getSquareDetails } from "../graphql/queries";
import { createPayment } from "../graphql/mutations";
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Select,
  Form,
  FormField,
  TextInput,
  Button,
  Spinner,
} from "grommet";
import dayjs from "dayjs";
import ShortUniqueId from "short-unique-id";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export default function Pay() {
  const [product, setProduct] = useState(null);
  const [selectedVariation, setSelectedVariation] = useState({});
  const [isRedirecting, setIsRedirecting] = useState(false);
  let params = useParams();

  useEffect(() => {
    loadProduct(params.productId);
  }, []);

  async function loadProduct(id) {
    const data = await API.graphql({
      query: getProduct,
      variables: { id },
      authMode: "API_KEY",
    });

    setProduct(data.data.getProduct);
  }

  async function createPaymentRecord(
    product_id,
    product_name_and_option,
    quantity,
    price,
    tester_name,
    tester_email
  ) {
    // Create a payment record in the db
    const createdAtDate = dayjs().tz("America/Chicago").format("YYYY-MM-DD");

    // create a 10 digit uuid for the payment id
    const uid = new ShortUniqueId({ length: 10, dictionary: "alphanum_lower" });
    const payId = uid.rnd();

    //
    try {
      const payment = await API.graphql({
        query: createPayment,
        variables: {
          input: {
            product_id: product_id,
            product_name_and_option: product_name_and_option,
            quantity: quantity,
            price: price,
            tester_name: tester_name,
            tester_email: tester_email,
            status: "pending",
            createdAtDate: createdAtDate,
          },
        },
        authMode: "API_KEY",
      });

      return payment.data;
    } catch (error) {
      console.log("Error creating payment: ", error);
    }
  }

  async function createQuickPayLink(name, amount, redirectUrlId) {
    try {
      const quickPay = await API.graphql({
        query: getSquareDetails,
        variables: {
          action: "createCheckoutLink",
          payload: JSON.stringify({
            name: name,
            amount: amount,
            redirectUrlId: redirectUrlId,
          }),
        },
        authMode: "API_KEY",
      });
      console.log("Quick pay link created: ", quickPay);
      return quickPay.data;
    } catch (error) {
      console.log("Error creating quick pay link: ", error);
    }
  }

  async function checkPaymentIdDoesNotExist(paymentId) {}

  function handleSubmit(value) {
    console.log("Form submitted: ", value);
    setIsRedirecting(true);
    const paymentDetails = createPaymentRecord(
      product.id,
      product.name + " " + selectedVariation.name,
      1,
      selectedVariation.price,
      value.testerName,
      value.testerEmail
    ).then((payment) => {
      console.log("Payment details in DB: ", payment);
      if (payment.createPayment.status === "pending") {
        const quickPayLink = createQuickPayLink(
          product.name + " " + selectedVariation.name,
          selectedVariation.price,
          payment.createPayment.id
        ).then((quickPay) => {
          console.log("Quick pay link: ", quickPay);
          window.location.href = JSON.parse(
            quickPay.getSquareDetails
          ).body.paymentLink;
        });
      }
    });
  }

  if (product !== null && isRedirecting === false) {
    return (
      <Box pad="medium" align="center" justify="center" fill>
        <Box pad="medium" align="center" justify="center" width="large">
          <Card fill align="center" pad="small" border>
            <CardHeader
              direction="column"
              justify="start"
              border="bottom"
              fill
              pad="small"
            >
              <Text textAlign="center" size="xxlarge" weight="bolder">
                {product.name}
              </Text>
              <Text textAlign="center">{product.notes}</Text>
            </CardHeader>
            <CardBody pad="medium" align="center" gap="small">
              {selectedVariation.price && (
                <Text size="large" weight="bold">
                  ${(selectedVariation.price / 100).toFixed(2)}
                </Text>
              )}
              <Select
                value={selectedVariation}
                onChange={({ option }) => setSelectedVariation(option)}
                placeholder="Select a variation"
                options={product.variations}
                labelKey="name"
                valueKey="price"
              />
            </CardBody>
          </Card>
        </Box>
        {selectedVariation.price && (
          <Box pad="medium" align="center" justify="center" width="large">
            <Card fill align="center" pad="small" border>
              <CardHeader
                direction="column"
                justify="start"
                border="bottom"
                fill
                pad="small"
              >
                Fill out the form below to register and proceed to the payment
                page.
              </CardHeader>
              <CardBody align="center" gap="small">
                <Form
                  align="center"
                  onSubmit={({ value }) => handleSubmit(value)}
                >
                  <FormField
                    name="testerName"
                    label="Tester Name"
                    width="medium"
                    required
                  >
                    <TextInput
                      name="testerName"
                      style={{
                        border: "1px solid #CCCCCC",
                      }}
                    />
                  </FormField>
                  <FormField
                    name="testerEmail"
                    label="Tester Email"
                    width="medium"
                    required
                  >
                    <TextInput
                      name="testerEmail"
                      style={{
                        border: "1px solid #CCCCCC",
                      }}
                    />
                  </FormField>
                  <Button type="submit" primary label="Pay Now" color="black" />
                </Form>
              </CardBody>
            </Card>
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Box align="center" justify="center" height="medium">
        <Spinner size="xlarge" />
        Loading...
      </Box>
    );
  }
}
