/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      id
      name
      embedCode
      proctorCode
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      id
      name
      embedCode
      proctorCode
      type
      createdAt
      updatedAt
    }
  }
`;

export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      id
      name
      embedCode
      proctorCode
      type
      createdAt
      updatedAt
    }
  }
`;
export const createWebcamLink = /* GraphQL */ `
  mutation CreateWebcamLink(
    $input: CreateWebcamLinkInput!
    $condition: ModelWebcamLinkConditionInput
  ) {
    createWebcamLink(input: $input, condition: $condition) {
      id
      embedid
      roomid
      proctor
      tester
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateWebcamLink = /* GraphQL */ `
  mutation UpdateWebcamLink(
    $input: UpdateWebcamLinkInput!
    $condition: ModelWebcamLinkConditionInput
  ) {
    updateWebcamLink(input: $input, condition: $condition) {
      id
      embedid
      roomid
      proctor
      tester
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteWebcamLink = /* GraphQL */ `
  mutation DeleteWebcamLink(
    $input: DeleteWebcamLinkInput!
    $condition: ModelWebcamLinkConditionInput
  ) {
    deleteWebcamLink(input: $input, condition: $condition) {
      id
      embedid
      roomid
      proctor
      tester
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const createWebcamRoom = /* GraphQL */ `
  mutation CreateWebcamRoom(
    $input: CreateWebcamRoomInput!
    $condition: ModelWebcamRoomConditionInput
  ) {
    createWebcamRoom(input: $input, condition: $condition) {
      id
      name
      embedCode
      ScCode
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateWebcamRoom = /* GraphQL */ `
  mutation UpdateWebcamRoom(
    $input: UpdateWebcamRoomInput!
    $condition: ModelWebcamRoomConditionInput
  ) {
    updateWebcamRoom(input: $input, condition: $condition) {
      id
      name
      embedCode
      ScCode
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteWebcamRoom = /* GraphQL */ `
  mutation DeleteWebcamRoom(
    $input: DeleteWebcamRoomInput!
    $condition: ModelWebcamRoomConditionInput
  ) {
    deleteWebcamRoom(input: $input, condition: $condition) {
      id
      name
      embedCode
      ScCode
      type
      createdAt
      updatedAt
    }
  }
`;
export const createInvigilExam = /* GraphQL */ `
  mutation CreateInvigilExam(
    $input: CreateInvigilExamInput!
    $condition: ModelInvigilExamConditionInput
  ) {
    createInvigilExam(input: $input, condition: $condition) {
      id
      examId
      title
      materials
      instructor
      email
      quantity
      instructions
      accom
      date
      format
      createdAt
      type
      custId
      updatedAt
    }
  }
`;
export const updateInvigilExam = /* GraphQL */ `
  mutation UpdateInvigilExam(
    $input: UpdateInvigilExamInput!
    $condition: ModelInvigilExamConditionInput
  ) {
    updateInvigilExam(input: $input, condition: $condition) {
      id
      examId
      title
      materials
      instructor
      email
      quantity
      instructions
      accom
      date
      format
      createdAt
      type
      custId
      updatedAt
    }
  }
`;
export const deleteInvigilExam = /* GraphQL */ `
  mutation DeleteInvigilExam(
    $input: DeleteInvigilExamInput!
    $condition: ModelInvigilExamConditionInput
  ) {
    deleteInvigilExam(input: $input, condition: $condition) {
      id
      examId
      title
      materials
      instructor
      email
      quantity
      instructions
      accom
      date
      format
      createdAt
      type
      custId
      updatedAt
    }
  }
`;
export const createHpeExam = /* GraphQL */ `
  mutation CreateHpeExam(
    $input: CreateHpeExamInput!
    $condition: ModelHpeExamConditionInput
  ) {
    createHpeExam(input: $input, condition: $condition) {
      id
      candidateToken
      proctorToken
      examName
      examStart
      examDuration
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateHpeExam = /* GraphQL */ `
  mutation UpdateHpeExam(
    $input: UpdateHpeExamInput!
    $condition: ModelHpeExamConditionInput
  ) {
    updateHpeExam(input: $input, condition: $condition) {
      id
      candidateToken
      proctorToken
      examName
      examStart
      examDuration
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteHpeExam = /* GraphQL */ `
  mutation DeleteHpeExam(
    $input: DeleteHpeExamInput!
    $condition: ModelHpeExamConditionInput
  ) {
    deleteHpeExam(input: $input, condition: $condition) {
      id
      candidateToken
      proctorToken
      examName
      examStart
      examDuration
      name
      createdAt
      updatedAt
    }
  }
`;
export const createLsoTester = /* GraphQL */ `
  mutation CreateLsoTester(
    $input: CreateLsoTesterInput!
    $condition: ModelLsoTesterConditionInput
  ) {
    createLsoTester(input: $input, condition: $condition) {
      id
      email
      fName
      lName
      createdAt
      updatedAt
    }
  }
`;
export const updateLsoTester = /* GraphQL */ `
  mutation UpdateLsoTester(
    $input: UpdateLsoTesterInput!
    $condition: ModelLsoTesterConditionInput
  ) {
    updateLsoTester(input: $input, condition: $condition) {
      id
      email
      fName
      lName
      createdAt
      updatedAt
    }
  }
`;
export const deleteLsoTester = /* GraphQL */ `
  mutation DeleteLsoTester(
    $input: DeleteLsoTesterInput!
    $condition: ModelLsoTesterConditionInput
  ) {
    deleteLsoTester(input: $input, condition: $condition) {
      id
      email
      fName
      lName
      createdAt
      updatedAt
    }
  }
`;

export const addGRoom = /* GraphQL */ `
  mutation AddGRoom(
    $query: MutationAddGRoomQueryInput
    $body: MutationAddGRoomBodyInput
  ) {
    addGRoom(query: $query, body: $body)
  }
`;

export const addLausdGRoom = /* GraphQL */ `
  mutation AddLausdGRoom(
    $query: MutationAddLausdGRoomQueryInput
    $body: MutationAddLausdGRoomBodyInput
  ) {
    addLausdGRoom(query: $query, body: $body)
  }
`;

export const addColibriGRoom = /* GraphQL */ `
  mutation AddColibriGRoom(
    $query: MutationAddColibriGRoomQueryInput
    $body: MutationAddColibriGRoomBodyInput
  ) {
    addColibriGRoom(query: $query, body: $body)
  }
`;

export const addDailyGRoom = /* GraphQL */ `
  mutation AddDailyGRoom(
    $query: MutationAddDailyGRoomQueryInput
    $body: MutationAddDailyGRoomBodyInput
  ) {
    addDailyGRoom(query: $query, body: $body)
  }
`;

export const addCraneGRoom = /* GraphQL */ `
  mutation AddCraneGRoom(
    $query: MutationAddCraneGRoomQueryInput
    $body: MutationAddCraneGRoomBodyInput
  ) {
    addCraneGRoom(query: $query, body: $body)
  }
`;

export const sendCmsCancelNotification = /* GraphQL */ `
  mutation cmsCancelNotification(
    $query: MutationCmsCancelNotificationQueryInput
    $body: MutationCmsCancelNotificationBodyInput
  ) {
    cmsCancelNotification(query: $query, body: $body)
  }
`;

export const grantAAAFoodHandlerAccess = /* GraphQL */ `
  mutation GrantFoodHandlerAccess(
    $query: MutationGrantFoodHandlerAccessQueryInput
    $body: MutationGrantFoodHandlerAccessBodyInput
  ) {
    grantFoodHandlerAccess(query: $query, body: $body)
  }
`;

export const revokeAAAFoodHandlerAccess = /* GraphQL */ `
  mutation RevokeFoodHandlerAccess(
    $query: MutationRevokeFoodHandlerAccessQueryInput
    $body: MutationRevokeFoodHandlerAccessBodyInput
  ) {
    revokeFoodHandlerAccess(query: $query, body: $body)
  }
`;

export const addCmsAppointmentEmail = /* GraphQL */ `
  mutation AddCmsAppointmentEmail(
    $query: MutationAddCmsAppointmentEmailQueryInput
    $body: MutationAddCmsAppointmentEmailBodyInput
  ) {
    addCmsAppointmentEmail(query: $query, body: $body)
  }
`;

export const ValidateOrder = /* GraphQL */ `
  mutation ValidateOrder(
    $input: UpdatePaidTesterInput!
    $condition: ModelPaidTesterConditionInput
  ) {
    updatePaidTester(input: $input, condition: $condition) {
      createdAt
      cust_email
      cust_name
      item_name
      item_quantity
      lastFour
      verified_by
      referenceId
      paymentId
      updatedAt
      verified_at
    }
  }
`;

export const updateDbtTester = /* GraphQL */ `
  mutation UpdateDBTTester(
    $input: UpdateDBTTesterInput!
    $condition: ModelDBTTesterConditionInput
  ) {
    updateDBTTester(input: $input, condition: $condition) {
      deliveryId
      appointment
      email
      id
      name
    }
  }
`;

export const updateCmsRegistration = /* GraphQL */ `
  mutation UpdateCmsRegistration(
    $input: UpdateCmsRegistrationInput!
    $condition: ModelCmsRegistrationConditionInput
  ) {
    updateCmsRegistration(input: $input, condition: $condition) {
      registration_id
      user_id
      exam_id
      appointment {
        appointment_id
        exam_date
      }
    }
  }
`;

export const updateCmsNonce = /* GraphQL */ `
  mutation UpdateCmsNonce(
    $input: UpdateCmsNonceInput!
    $condition: ModelCmsNonceConditionInput
  ) {
    updateCmsNonce(input: $input, condition: $condition) {
      nonce_id
    }
  }
`;

export const updateCmsSession = /* GraphQL */ `
  mutation UpdateCmsSession(
    $input: UpdateCmsSessionInput!
    $condition: ModelCmsSessionConditionInput
  ) {
    updateCmsSession(input: $input, condition: $condition) {
      session_id
      registration_id
      exam_id
      user_id
      progress
      first_name
      last_name
      email_address
    }
  }
`;

export const createCmsSession = /* GraphQL */ `
  mutation CreateCmsSession(
    $input: CreateCmsSessionInput!
    $condition: ModelCmsSessionConditionInput
  ) {
    createCmsSession(input: $input, condition: $condition) {
      session_id
    }
  }
`;

export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      note_format
      pre_chat_form
      tawk_property_link
    }
  }
`;

export const CreateSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      appointment
      candidate_id
      client_id
      client_name
      createdAt
      email
      end_time
      exam
      exam_password
      first_name
      flag
      google_meet_link
      id
      instructor
      last_name
      lesson_id
      notes
      pass
      phone
      proctor
      proctor_history
      receipt_number
      school
      score
      start_time
      status
    }
  }
`;

export const UpdateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      appointment
      candidate_id
      client_id
      client_name
      createdAt
      email
      end_time
      exam
      exam_password
      first_name
      flag
      google_meet_link
      id
      instructor
      last_name
      lesson_id
      notes
      pass
      phone
      proctor
      proctor_history
      receipt_number
      school
      score
      start_time
      status
    }
  }
`;

export const DeleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
    }
  }
`;

export const UpdateTwilioSCRoom = /* GraphQL */ `
  mutation updateTwilioSCRoom(
    $input: UpdateTwilioSCRoomInput!
    $condition: ModelTwilioSCRoomConditionInput
  ) {
    updateTwilioSCRoom(input: $input, condition: $condition) {
      id
      proctor
      tester
      status
      type
      createdAt
    }
  }
`;

export const UpdateCraneGuageSession = /* GraphQL */ `
  mutation UpdateCraneGuageSession(
    $input: UpdateCraneGuageSessionInput!
    $condition: ModelCraneGuageSessionConditionInput
  ) {
    updateCraneGuageSession(input: $input, condition: $condition) {
      session_id
      user_name
      user_email
      test_name
      password
      exam_url
      proctor_instructions
      proctor
      start_time
      end_time
      flag
      notes
      google_meet_id
      createdAt
      updatedAt
    }
  }
`;

export const UpdateAAAFoodHandlerSession = /* GraphQL */ `
  mutation UpdateAAAFoodHandlerSession(
    $input: UpdateAAAFoodHandlerSessionInput!
    $condition: ModelAAAFoodHandlerSessionConditionInput
  ) {
    updateAAAFoodHandlerSession(input: $input, condition: $condition) {
      CourseLanguage
      EmailAddress
      ExamId
      Name
      SessionId
      StudentId
      Status
      ExamStatus
      createdAt
    }
  }
`;

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      email
      googleAccessToken
      googleRefreshToken
      googleTokenExpiry
      id
    }
  }
`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      email
      googleAccessToken
      googleRefreshToken
      googleTokenExpiry
      id
    }
  }
`;

export const createGoogleRoom = /* GraphQL */ `
  mutation CreateGoogleRoom($input: CreateGoogleRoomInput!) {
    createGoogleRoom(input: $input) {
      candidateEmail
      candidateName
      clientId
      clientName
      createdAt
      createdAtDate
      googleAccessToken
      googleRefreshToken
      googleTokenExpiry
      id
      organizer
      mobileRoomCode
      mobileRoomLink
      mobileRoomName
      webcamRoomLink
      webcamRoomName
      webcamRoomCode
    }
  }
`;

export const updateGoogleRoom = /* GraphQL */ `
  mutation UpdateGoogleRoom($input: UpdateGoogleRoomInput!) {
    updateGoogleRoom(input: $input) {
      candidateEmail
      candidateName
      clientId
      clientName
      createdAt
      createdAtDate
      googleAccessToken
      googleRefreshToken
      googleTokenExpiry
      id
      organizer
      mobileRoomCode
      mobileRoomLink
      mobileRoomName
      webcamRoomLink
      webcamRoomName
      webcamRoomCode
    }
  }
`;

export const createPayment = /* GraphQL */ `
  mutation CreatePayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      createdAt
      createdAtDate
      id
      price
      payer_name
      payer_email
      order_id
      product_id
      product_name_and_option
      quantity
      status
      tester_email
      tester_name
      updatedAt
    }
  }
`;
